<template>
	<div>
		<div class="row mb-3">
			<div class="col-12">
				<h1><em class="far fa-id-card pinkslip"></em> Pink Slips<span v-show="policynum"> For {{policynum}}</span></h1>
			</div>
		</div>

		<div class="alert alert-info text-center" v-show="filteredDocuments.length == 0 && !loading"><em class="fas fa-info-circle"></em> No pink slips available.</div>
		<div class="text-center" v-show="loading"><em class="fas fa-circle-notch fa-spin fa-3x"></em><br>Loading Documents...</div>

		<div class="list-group" v-show="filteredDocuments.length > 0">
			<router-link :to="'/document/' + d.document_id" v-for="d in filteredDocuments" :key="d.document_id" class="list-group-item list-group-item-action d-flex justify-content-between">
				<div class="mr-2">{{d.title}}</div>
				<div class="ml-2 nowrap">Proof of Insurance</div>
			</router-link>
		</div>
	</div>
</template>

<script>
export default
{
	name: 'Pinkslips',
	props: ["session_id", "logged_in"],
	data: function()
	{
		return {
			documents: [],
			policynum: null,
			loading: true
		}
	},
	mounted: function()
	{
		if(!this.logged_in)
		{
			return;
		}

		this.policynum = this.$route.params.policynum;
		this.loadDocuments(this.policynum);
	},
	computed:
	{
		filteredDocuments: function()
		{
			var docs = [];

			for(var i=0; i<this.documents.length; i++)
			{
				if(this.documents[i].document_type == "pinkslip")
				{
					docs.push(this.documents[i]);
				}
			}

			return docs;
		}
	},
	methods:
	{
		loadDocuments: function(policynum)
		{
			var vm = this;
			vm.loading = true;
			vm.documents = [];

			this.CORS("GET", "/documents" + (policynum ? "/" + policynum : ""), null,
			function(response)
			{
				vm.documents = response;
				vm.loading = false;
			}, function(response)
			{
				vm.showError("Error Loading Documents", response.responseText, true, null);
				vm.loading = false;
			})
		}
	},
	watch:
	{
		'$route' (from, to)
		{
			if(from == to)
			{
				return;
			}

			this.policynum = this.$route.params.policynum;
			this.loadDocuments(this.policynum);
		}
	}
}
</script>
